<template>
  <!-- 成交信息公示 -->
  <div style="padding-top: 85px;">
    <div class="success-detail">
      <div class="s-title">
        <span class="title">{{projectInfo.bt}}</span>
        <div class="button">
          <el-button type="primary" @click="showProDetail">查看项目详情</el-button>
          <el-button type="primary" @click="goback">返回</el-button>
        </div>
      </div>
      <div class="table-con">
          <div class="define-table">
            <table style="table-layout:fixed">
              <tr>
                <td class="name">项目名称</td>
                <td class="value">{{projectInfo.xmmc}}</td>
                <td class="name">项目编号</td>
                <td class="value">{{projectInfo.xmbh}}</td>
              </tr>
              <tr>
                <td class="name">交易方式</td>
                <td class="value" colspan="3">{{projectInfo.jyfz}}</td>
              </tr>
              <tr>
                <td class="name">项目底价</td>
                <td class="value">{{projectInfo.gpjg}}元/年</td>
                <td class="name">成交时间</td>
                <td class="value">{{projectInfo.cjsj}}</td>
              </tr>
              <tr>
                <td class="name">项目所在区域</td>
                <td class="value" :colspan="3">{{projectInfo.xmdz}}</td>
              </tr>
              <tr>
                <td class="name">转出方</td>
                <td class="value" :colspan="3">{{projectInfo.zcf}}</td>
              </tr>
              <tr>
                <td class="name">受让方</td>
                <td class="value" :colspan="3">{{projectInfo.srf}}</td>
              </tr>
              <tr>
                <td class="name">转出面积/数量</td>
                <td class="value" :colspan="3">{{projectInfo.zcmj}}亩</td>
              </tr>
              <tr>
                <td class="name">转出期限</td>
                <td class="value" :colspan="3">{{projectInfo.zcqx}}</td>
              </tr>
              <tr>
                <td class="name">成交价格</td>
                <td class="value">{{projectInfo.dqbj}}元/年</td>
                <td class="name">联系电话</td>
                <td class="value">{{projectInfo.lxfs}}</td>
              </tr>
              <tr>
                <td class="name" :rowspan="2">备注</td>
                <td class="value" :rowspan="2" :colspan="3">{{projectInfo.bz}}</td>
              </tr>
              <tr></tr>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getSuccessDetail } from '../../api/announcement'
export default {
  data() {
    return {
      projectInfo: {}
    }
  },
  created() {
    this.initData();
  },
  methods: {
    showProDetail() {
      // 查看项目详情
      this.$router.push({name: 'projectDetail', query: {c: this.$route.query.c}})
    },
    goback() {
      this.$router.go(-1)
    },
    async initData() {
      // 获取详情
      let params = {
        xmbh: this.$route.query.c
      }
      let res = await getSuccessDetail(params);
      let {status, data} = res;
      if(status) {
        this.projectInfo = data[0];
      }
    }
  }
}
</script>

<style lang="less" scoped>
.success-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  min-height: 1137px;
  .s-title {
    position: relative;
    .title {
      margin-left: 52px;
    }
    .button {
      position: absolute;
      right: 63px;
      top: 10px;
    }
  }
  .table-con {
    margin-top: 30px;
    padding: 0 18px;
    .define-table {
      width: 100%;
      table {
        width: 100%;
        text-align: center;
        border: 1px solid #c9c9c9;
        border-bottom: none;
        border-top: 4px solid #00a0e9;
        tr {
          height: 40px;
          width: 100%;
          td {
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
            border-bottom: 1px solid #c9c9c9;
          }
        }
        .value {
          width: 300px;
          border-right: none;
        }
        .name {
          width: 270px;
          border-right: none;
        }
        .name:first-child {
          border-left: none;
        }

        .special {
          width:885px;
          height: 40px;
          border-left: 1px solid #c9c9c9;
          .special-td {
            width: 410px;
            height: 36px;
            line-height: 36px;
          }
          .special-td-2 {
             width: 468px;
             height: 36px;
             line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
